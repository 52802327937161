
import { useMarketStore } from '~/stores/market'
import startObserverLoading from '@/mixins/startObserverLoading'
import { useCustomer } from '@/stores/customer'

export default {
  name: 'MPLandingCategories',
  mixins: [startObserverLoading],
  setup() {
    const marketStore = useMarketStore()
    const customerStore = useCustomer()
    return {
      marketStore,
      customerStore,
    }
  },
  data() {
    return {
      isLandingCategoriesVisible: false,
    }
  },
  async fetch() {
    if (this.customerStore.isBot) {
      await this.marketStore.getPopularCategories()
      if (this.marketStore.popularCategoriesList.length) {
        this.isLandingCategoriesVisible = true
      }
    }
  },
  async mounted() {
    if (!this.$device.isCrawler) {
      this.startObserverLoading(
        this.$refs.LandingCategories,
        this.showLandingCategories,
      )
    }
  },
  methods: {
    handleCategoryClick(category) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'select_promotion',
          creative_slot: 'homepage_actual_today',
          creative_url: this.url(category),
          publish_date: null,
          unpublish_date: null,
          location_code: null,
          object: 'card',
          creative_id: category?.id,
          category_ids: [category?.category_id],
        })
      }
    },
    async showLandingCategories() {
      await this.marketStore.getPopularCategories()
      if (this.marketStore.popularCategoriesList.length) {
        this.isLandingCategoriesVisible = true
      }
    },
    url(category) {
      return this.localePath({
        name: 'categories-slug',
        params: {
          slug: `${category?.category_id}-${category?.slugged_name}`,
        },
      })
    },
  },
}
